import React from 'react'
import { graphql } from 'gatsby'
import { Container, Inner} from '../components/responsive/'
import H1 from '../components/h1'
import ContentHTML from '../components/contentHTML'
import Header from '../components/header/header'
import ContainerFooter from '../components/containerFooter'
import Footer from '../components/footer/footer'
import Wrap from '../components/wrap'
import Metadata from '../components/metadata'
import './index.css'

export default ({ data }) => (
  <div>
    <Metadata title='多様性とインクルージョン'/>
    <Wrap>
      <Header active='inclusion'/>
      <Container backgroundColor='#FFF' paddingTop='1em' marginTop='4.5em' paddingBottom='280px'>
      <H1 fluid={data.diversity.childImageSharp.fluid} long>
      多様性とインクルージョン
      </H1>
      <Inner marginTop='2em' lineHeight='1.6' fontWeight='normal' marginBottom='2em'>
        <ContentHTML>
          <div dangerouslySetInnerHTML={{__html: data.camp2021.rest.contents.field_divercity}}/>
        </ContentHTML>
      </Inner>
      </Container>
      <Footer/>
    </Wrap>
  </div>
)


export const query = graphql`
query {
  diversity:file(relativePath: { eq: "多様性とインクルージョン.png" }) {
    childImageSharp {
      fluid(maxWidth: 1200, quality: 100) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
  camp2021{
    rest{
      contents{
        field_divercity
      }
    }
  }
}
`
